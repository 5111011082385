
@import "../css/variables";

.text-center {
  text-align: center;
}

.login {
  display: flex;
  align-items: center;
  max-width: 26em;
  padding: 0;

  &__container {
    padding: 2em;

    &--title {
      justify-content: center;
      padding-bottom: 0%;
    }

    &--actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--qr-code {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &--app-version {
      display: flex;
      justify-content: center;
      margin-top: 1em;
    }
  }
}

.bold {
  font-weight: 500;
}

